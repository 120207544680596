import { ref } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery, useResult } from '@vue/apollo-composable';

import { useNotification, useLoading } from '@/modules/core';

export function useConfigs(businessId) {
  const { error } = useNotification();

  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
  } = useQuery(CONFIGS_QUERY, { businessId }, () => ({
    enabled: !!businessId.value,
  }));
  const configs = useResult(result, []);
  const vars = ref({ businessId });
  const loading = useLoading(queryLoading, vars);

  onError((err) => {
    console.error('bookkeeping - useConfigs', err);
    error();
  });

  return {
    configs,
    loading,
    refetch,
  };
}

export const CONFIGS_QUERY = gql`
  query getConfigs($businessId: ID) {
    bookkeepingConfigs(businessId: $businessId) {
      id
      bookkeeper {
        email
        phone
        firstName
        lastName
        company {
          crn
          name
        }
      }
      billingSending {
        sendingMethod
        email
        bookkeeperId
        autoSend
        fromDate
        csvExport
      }
      paymentSending {
        email
        autoSend
      }
      documentSending {
        email
        autoSend
        documentTypes {
          invoice
          invoiceReceipt
          receipt
          creditNote
          billInvoice
          billInvoiceReceipt
          other
        }
      }
    }
  }
`;
