import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

export function useBatchResend() {
  const { error } = useNotification();
  const { mutate, loading, onDone, onError } = useMutation(BATCH_RESEND_MUTATION);

  onError((err) => {
    console.error('bookkeeping - useBatchResend', err);
    error();
  });

  return {
    resendBatch: mutate,
    loading,
    onDone,
    onError,
  };
}

const BATCH_RESEND_MUTATION = gql`
  mutation resendBatch($id: ID, $email: String) {
    bookkeepingBatchResend(id: $id, email: $email)
  }
`;
