import { gql } from '@apollo/client/core';
import { useQuery, useResult } from '@vue/apollo-composable';
import { computed } from 'vue';

import { useNotification, useLoading } from '@/modules/core';

export function usePayments(variables) {
  const { error } = useNotification();

  const options = computed(() => ({ enabled: !!variables.value.ids.length && !!variables.value.businessId }));
  const { result, loading: queryLoading, onError, refetch } = useQuery(PAYMENTS_QUERY, variables, options);
  const paymentConnections = useResult(result, { nodes: [], totalCount: 0 });
  const loading = useLoading(queryLoading, variables, options);

  onError((err) => {
    console.error('bookkeeping - usePayments', err);
    error();
  });

  return {
    payments: computed(() => paymentConnections.value.nodes),
    loading,
    refetch,
  };
}

const PAYMENTS_QUERY = gql`
  query getPayments($ids: [ID!], $businessId: ID!) {
    payments(ids: $ids, businessId: $businessId) {
      nodes {
        id
        date
        supplier {
          id
          name
        }
        billingLinks {
          billing {
            id
            date
          }
        }
        amount
        isRefund
      }
    }
  }
`;
