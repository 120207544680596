import { currency } from '@/locale/numberConfig';
import { options } from '@/locale/dateConfig';
import i18n from '@/imports/startup/client/i18n';

export function formatCentMoney(value) {
  return typeof value === 'number' && !Number.isNaN(value)
    ? Number(value / 100).toLocaleString(i18n.locale, currency)
    : '-';
}

export function formatDollarMoney(value) {
  return typeof value === 'number' && !Number.isNaN(value) ? Number(value).toLocaleString(i18n.locale, currency) : '-';
}

export function formatDate(ms) {
  return ms ? new Date(ms).toLocaleDateString(i18n.locale, options.short) : '-';
}

export function formatDateShort(ms) {
  return ms
    ? new Date(ms).toLocaleDateString(i18n.locale, {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
      })
    : '';
}
