import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';

import { useNotification, useLoading } from '@/modules/core';

export function useBillings(variables) {
  const { error } = useNotification();

  const options = computed(() => ({
    enabled: !!variables.value.ids.length && !!variables.value.businessId,
  }));
  const { result, loading: queryLoading, onError, refetch } = useQuery(BILLINGS_QUERY, variables, options);
  const billings = computed(() => result.value?.billings?.nodes ?? []);
  const loading = useLoading(queryLoading, variables, options);

  onError((err) => {
    console.error('bookkeeping - useBillings', err);
    error();
  });

  return {
    billings,
    loading,
    refetch,
  };
}

const BILLINGS_QUERY = gql`
  query getBillings($businessId: ID!, $ids: [ID]) {
    billings(businessId: $businessId, ids: $ids) {
      nodes {
        id
        date
        businessId
        supplier {
          id
          name
          number
        }
        taxAmount
        netAmount
        totalAmount
        eventReferences {
          documentId
          regulatory
          document {
            id
            issueDate
            recordedAt
            documentNumber
            type
            filePathUrl
          }
        }
        createdAt
      }
    }
  }
`;
