import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';

import { CONFIGS_QUERY } from '../queries/configs';

export function useConfigCreate(businessId) {
  const { mutate, onDone, onError } = useMutation(CONFIG_CREATE_MUTATION, {
    update: (cache, { data: { bookkeepingConfigCreate: newConfig } }) => {
      const { bookkeepingConfigs } = cache.readQuery({
        query: CONFIGS_QUERY,
        variables: { businessId: businessId.value },
      });

      cache.writeQuery({
        query: CONFIGS_QUERY,
        variables: { businessId: businessId.value },
        data: { bookkeepingConfigs: [...bookkeepingConfigs, newConfig] },
      });
    },
  });

  return {
    createConfig: mutate,
    onDone,
    onError,
  };
}

const CONFIG_CREATE_MUTATION = gql`
  mutation configCreate($data: BookkeepingConfigInput) {
    bookkeepingConfigCreate(data: $data) {
      id
      bookkeeper {
        email
        phone
        firstName
        lastName
        company {
          crn
          name
        }
      }
      billingSending {
        email
        autoSend
        fromDate
        csvExport
      }
      paymentSending {
        email
        autoSend
      }
      documentSending {
        email
        autoSend
        documentTypes {
          invoice
          invoiceReceipt
          receipt
          creditNote
          billInvoice
          billInvoiceReceipt
          other
        }
      }
    }
  }
`;
