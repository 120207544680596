import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';

import { useNotification, useLoading } from '@/modules/core';

export function useBatches(variables, options) {
  const { error } = useNotification();

  const { result, loading: queryLoading, onError, refetch } = useQuery(BATCHES_QUERY, variables, options);
  const batches = computed(() => result?.value?.bookkeepingBatches?.nodes ?? []);

  const loading = useLoading(queryLoading, variables, options);

  onError((err) => {
    console.error('bookkeeping - useBatches', err);
    error();
  });

  return {
    batches,
    refetch,
    loading,
  };
}

const BATCHES_QUERY = gql`
  query bookkeepingBatches($type: BookkeepingBatchType, $businessId: ID) {
    bookkeepingBatches(type: $type, businessId: $businessId) {
      nodes {
        id
        refs
        sentTo
        sendingMethod
        createdAt
        createdBy {
          profile {
            firstName
            lastName
          }
        }
        status
      }
    }
  }
`;
