import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';

export function useConfigUpdate() {
  const { mutate, onDone, onError } = useMutation(CONFIG_PATCH_MUTATION);

  return {
    updateConfig: mutate,
    onDone,
    onError,
  };
}

const CONFIG_PATCH_MUTATION = gql`
  mutation patchConfig($id: ID!, $data: BookkeepingConfigInput) {
    bookkeepingConfigPatch(id: $id, data: $data) {
      id
      bookkeeper {
        email
        phone
        firstName
        lastName
        company {
          crn
          name
        }
      }
      billingSending {
        sendingMethod
        email
        autoSend
        fromDate
        csvExport
      }
      paymentSending {
        email
        autoSend
      }
      documentSending {
        email
        autoSend
        documentTypes {
          invoice
          invoiceReceipt
          receipt
          creditNote
          billInvoice
          billInvoiceReceipt
          other
        }
      }
    }
  }
`;
