<template>
  <div>
    <div class="mb-4 d-flex justify-content-between align-items-center">
      <h3>
        {{ $t('bookkeeping.bookkeepingManagement.unsentDocuments.header') }}
      </h3>
      <el-tooltip
        :content="getTooltipContent"
        effect="dark"
        :disabled="!isSendingDisabled"
        :visible-arrow="false"
        placement="top"
      >
        <span>
          <Button :disabled="isSendingDisabled" type="primary" @click="sendModalOpen = true">
            {{ $t('bookkeeping.send') }}
          </Button>
        </span>
      </el-tooltip>
    </div>
    <Table
      v-loading="loading"
      :columns="columns"
      :data="tableData"
      :active-sort="activeSort"
      show-index
      rounded
      border
      @row-click="handleRowClick"
    >
      <template #cell-index="{ rowIndex }">
        {{ rowIndex + 1 + pageSize * currentPageIndex }}
      </template>
      <template #cell-recordingDate="{ rowData: { recordingDate } }">
        {{ formatDate(recordingDate) }}
      </template>
      <template #cell-issueDate="{ rowData: { issueDate } }">
        {{ formatDate(issueDate) }}
      </template>
      <template #filter-supplier>
        <el-input
          v-model="supplierSearchFilter"
          :placeholder="$t('commons.searchWithDots')"
          class="text-typography-primary"
        >
          <template #prefix>
            <div class="d-flex align-items-center h-100">
              <SearchIcon width="20px" height="20px" />
            </div>
          </template>
        </el-input>
      </template>
      <template #cell-documentType="{ rowData: { documentType } }">
        {{ $t(`document.exports.schema.type.shortName.${documentType}`) }}
      </template>
      <template #cell-netAmount="{ rowData: { netAmount } }">
        {{ formatMoney(netAmount) }}
      </template>

      <template #cell-taxAmount="{ rowData: { taxAmount } }">
        {{ formatMoney(taxAmount) }}
      </template>
      <template #cell-totalAmount="{ rowData: { totalAmount } }">
        {{ formatMoney(totalAmount) }}
      </template>
    </Table>
    <div class="d-flex justify-content-end my-4">
      <el-pagination
        v-if="documents.length && documents.length > pageSize"
        layout="prev, pager, next, jumper"
        small
        background
        :total="documents.length"
        :page-size="pageSize"
        :current-page.sync="currentPage"
      />
    </div>
    <DocumentSendingModal
      v-if="sendModalOpen"
      :type="BATCH_TYPE.DOCUMENT"
      :docs="documents"
      :send-email="config.documentSending.email"
      :from-date="documentsFromDate"
      @close="sendModalOpen = false"
      @send="handleSendEvent"
    />
    <DocumentModal
      v-if="displayedDocument"
      visible
      :document-id="displayedDocument"
      @close="displayedDocument = null"
    />
  </div>
</template>

<script>
import { ref, getCurrentInstance } from 'vue';
import { DateTime } from 'luxon';

import { DocumentModal } from '@/modules/documentModal';
import { SearchIcon } from '@/assets/icons';
import { Table, Button } from '@/modules/core';
import { useTenancy } from '@/modules/auth';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { BATCH_TYPE } from '../batch';
import { useBatchSend } from '../composition/batch';
import { formatDate } from '../formatters';
import DocumentSendingModal from './DocumentSendingModal';

const TABLE_HEADERS = {
  RECORDING_DATE: 'recordingDate',
  ISSUE_DATE: 'issueDate',
  SUPPLIER: 'supplier',
  DOCUMENT_TYPE: 'documentType',
  DOCUMENT_NUMBER: 'documentNumber',
  NET_AMOUNT: 'netAmount',
  TAX_AMOUNT: 'taxAmount',
  TOTAL_AMOUNT: 'totalAmount',
};

const getSortCompare = (direction, columnKey) => {
  switch (columnKey) {
    case TABLE_HEADERS.RECORDING_DATE:
      return (a, b) => (a[TABLE_HEADERS.RECORDING_DATE] - b[TABLE_HEADERS.RECORDING_DATE]) * direction;

    case TABLE_HEADERS.ISSUE_DATE:
      return (a, b) => (new Date(a[TABLE_HEADERS.ISSUE_DATE]) - new Date(b[TABLE_HEADERS.ISSUE_DATE])) * direction;

    case TABLE_HEADERS.SUPPLIER:
      return (a, b) => {
        if (a[TABLE_HEADERS.SUPPLIER] && b[TABLE_HEADERS.SUPPLIER]) {
          return a[TABLE_HEADERS.SUPPLIER].localeCompare(b[TABLE_HEADERS.SUPPLIER]) * direction;
        }
        return -1 * direction;
      };

    case TABLE_HEADERS.TOTAL_AMOUNT:
      return (a, b) => (a[TABLE_HEADERS.TOTAL_AMOUNT] - b[TABLE_HEADERS.TOTAL_AMOUNT]) * direction;

    default:
      return () => 0;
  }
};

export default {
  components: { Table, Button, DocumentSendingModal, DocumentModal, SearchIcon },
  props: {
    documents: { type: Array, required: true },
    loading: { type: Boolean, required: true },
    config: { type: Object, required: true },
  },
  setup() {
    const root = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();
    const { formatCentsToCurrency } = useCurrency();
    const { sendBatch, onDone, onError } = useBatchSend();

    onDone(() => root.$message.success(root.$t('bookkeeping.sendModal.sendMessages.success')));
    onError(() => root.$message.error(root.$t('bookkeeping.sendModal.sendMessages.error')));

    return {
      formatCentsToCurrency,
      BATCH_TYPE,
      activeSort: ref({ direction: -1, columnKey: TABLE_HEADERS.RECORD_DATE }),
      sendModalOpen: ref(false),
      sendBatch,
      displayedDocument: ref(null),
      pageSize: 50,
      currentPageIndex: ref(0),
      supplierSearchFilter: ref(''),
      currentTenant,
    };
  },
  computed: {
    isSendingDisabled() {
      return !this.documents.length || !this.config?.documentSending?.email;
    },
    getTooltipContent() {
      if (!this.config?.documentSending?.email) {
        return this.$t('bookkeeping.sendModal.sendMessages.disabled', {
          type: this.$t('bookkeeping.bookkeepingManagement.tabs.documents'),
        });
      } else {
        return this.$t('bookkeeping.sendModal.sendMessages.emptyContent', {
          type: this.$t('bookkeeping.bookkeepingManagement.tabs.documents'),
        });
      }
    },
    columns() {
      return [
        {
          header: this.translateTableHeader('recordDate'),
          key: TABLE_HEADERS.RECORDING_DATE,
          width: '120px',
          sortCallback: (direction) => (this.activeSort = { direction, columnKey: TABLE_HEADERS.RECORDING_DATE }),
        },
        {
          header: this.translateTableHeader('issueDate'),
          key: TABLE_HEADERS.ISSUE_DATE,
          width: '120px',
          sortCallback: (direction) => (this.activeSort = { direction, columnKey: TABLE_HEADERS.ISSUE_DATE }),
        },
        {
          header: this.translateTableHeader('supplier'),
          key: TABLE_HEADERS.SUPPLIER,
          width: '150px',
          sortCallback: (direction) => (this.activeSort = { direction, columnKey: TABLE_HEADERS.SUPPLIER }),
          filterActive: !!this.supplierSearchFilter,
        },
        {
          header: this.translateTableHeader('documentType'),
          key: TABLE_HEADERS.DOCUMENT_TYPE,
          width: '120px',
        },
        {
          header: this.translateTableHeader('documentNumber'),
          key: TABLE_HEADERS.DOCUMENT_NUMBER,
          width: '130px',
        },
        {
          header: this.translateTableHeader('netAmount'),
          key: TABLE_HEADERS.NET_AMOUNT,
        },
        {
          header: this.translateTableHeader('taxAmount'),
          key: TABLE_HEADERS.TAX_AMOUNT,
          width: '160px',
        },
        {
          header: this.translateTableHeader('totalAmount'),
          key: TABLE_HEADERS.TOTAL_AMOUNT,
          sortCallback: (direction) => (this.activeSort = { direction, columnKey: TABLE_HEADERS.TOTAL_AMOUNT }),
        },
      ];
    },
    currentPage: {
      get() {
        return this.currentPageIndex + 1;
      },
      set(index) {
        this.currentPageIndex = index - 1;
      },
    },
    tableData() {
      return this.documents
        .map((document) => {
          const { id: documentId, recordedAt, issueDate, type: documentType, documentNumber } = document;
          return {
            [TABLE_HEADERS.RECORDING_DATE]: recordedAt,
            [TABLE_HEADERS.ISSUE_DATE]: issueDate,
            [TABLE_HEADERS.SUPPLIER]: document.supplier?.name || '-',
            [TABLE_HEADERS.DOCUMENT_TYPE]: documentType,
            [TABLE_HEADERS.DOCUMENT_NUMBER]: documentNumber || '-',
            [TABLE_HEADERS.NET_AMOUNT]: document.netAmount,
            [TABLE_HEADERS.TAX_AMOUNT]: document.taxAmount,
            [TABLE_HEADERS.TOTAL_AMOUNT]: document.totalAmount,
            documentId,
          };
        })
        .filter(({ supplier, documentType }) => {
          if (documentType === 'other' && !this.supplierSearchFilter) {
            return true;
          }
          return supplier?.includes(this.supplierSearchFilter);
        })
        .sort(getSortCompare(this.activeSort.direction, this.activeSort.columnKey))
        .slice(this.currentPageIndex * this.pageSize, this.currentPageIndex * this.pageSize + this.pageSize);
    },

    documentsFromDate() {
      const documentsSortedByIssueDate = [...this.documents]
        .filter((d) => d.issueDate)
        .sort((a, b) => new Date(a.issueDate) - new Date(b.issueDate));
      return DateTime.fromISO(documentsSortedByIssueDate[0].issueDate).toMillis();
    },
  },
  methods: {
    formatDate,
    formatMoney(value) {
      return this.formatCentsToCurrency(value) ?? '-';
    },
    async handleSendEvent({ toDate }) {
      await this.sendBatch({
        refs: this.documents.filter(({ issueDate }) => new Date(issueDate) <= toDate).map(({ id }) => id),
        type: BATCH_TYPE.DOCUMENT,
        businessId: this.currentTenant.id,
      });
      this.$emit('batch-sent');
      this.sendModalOpen = false;
    },
    handleRowClick(rowIndex) {
      this.displayedDocument = this.tableData[rowIndex].documentId;
    },
    translateTableHeader(columnKey) {
      return this.$t(`bookkeeping.bookkeepingManagement.unsentDocuments.table.headers.${columnKey}`);
    },
  },
};
</script>
