import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

export function useBatchSend() {
  const { error } = useNotification();

  const { mutate, loading, onDone, onError } = useMutation(BILLING_BATCH_SEND_MUTATION);

  onError((param) => {
    console.error('bookkeeping - useBatchSend', param);
    error();
  });

  return {
    sendBatch: mutate,
    loading,
    onDone,
    onError,
  };
}

const BILLING_BATCH_SEND_MUTATION = gql`
  mutation sendDocumentsBatch($businessId: ID, $type: BookkeepingBatchType, $refs: [ID]) {
    bookkeepingBatchCreate(businessId: $businessId, type: $type, refs: $refs)
  }
`;
