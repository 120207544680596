import { gql } from '@apollo/client/core';
import { useQuery, useResult } from '@vue/apollo-composable';

import { useNotification, useLoading } from '@/modules/core';

export function useBatch(variables) {
  const { error } = useNotification();

  const { result, loading: queryLoading, onError, refetch } = useQuery(BATCH_QUERY, variables);
  const batch = useResult(result, null);

  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('bookkeeping - useBatch', err);
    error();
  });

  return {
    batch,
    refetch,
    loading,
  };
}

const BATCH_QUERY = gql`
  query getBatch($id: ID!) {
    bookkeepingBatch(id: $id) {
      id
      type
      refs
      sentTo
      sendingMethod
      createdAt
      createdBy {
        profile {
          firstName
          lastName
        }
      }
      status
    }
  }
`;
