import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useQuery, useResult } from '@vue/apollo-composable';
import { useNotification, useLoading } from '@/modules/core';

export function useUnsentDocuments(variables, options) {
  const { error } = useNotification();

  const { result, loading: queryLoading, onError, refetch } = useQuery(UNSENT_DOCUMENTS_QUERY, variables, options);
  const unsentDocumentsConnection = useResult(result, { nodes: [], totalCount: 0 });
  const unsentDocuments = computed(() => unsentDocumentsConnection.value.nodes);
  const totalCount = computed(() => unsentDocumentsConnection.value.totalCount);

  const loading = useLoading(queryLoading, variables, options);

  onError((err) => {
    console.error('useUnsentDocuments', err);
    error();
  });

  return {
    unsentDocuments,
    totalCount,
    loading,
    refetch,
  };
}

const UNSENT_DOCUMENTS_QUERY = gql`
  query getUnsentDocuments($type: BookkeepingUnsentDocumentType, $businessId: ID) {
    bookkeepingUnsentDocuments(type: $type, businessId: $businessId) {
      nodes {
        ref
      }
      totalCount
    }
  }
`;
